import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginApi } from '../services/auth-api';
import './css/login.css';

const Login = () => {

    const [state, setState] = useState({
        email: "",
        password: ""
    });
    const [error, setError] = useState({
        email: "",
        password: "",
        credentials: ""
    })
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault();
        //check validation
        if (validation()) {
            //call services api
            await loginApi({
                username: state.email,
                password: state.password
            }).then(res => {
                // console.log(res.data.data)
                localStorage.setItem('token', res);
                navigate("/quiz");
            }).catch(err => {
                setError({ ...error, credentials: err.error });
                console.log(err.error)
            })
        }
    }

    // check input field validation
    const validation = () => {

        let flag = true;
        let error = {
            email: '',
            password: ''
        };

        if (state.email === null || state.email === undefined || state.email === "") {

            error.email = "The email field is required.";

            flag = false;
        } else {

            if (!validateEmail(state.email)) {
                error.email = "The email must be a valid email address.";
                flag = false;
            }
        }

        if (state.password === null || state.password === undefined || state.password === "") {

            error.password = "The password field is required.";

            flag = false;
        }

        setError({ ...error });

        return flag;
    }
    // check email validation
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <>
            <div className="authincation-content">
                <section className="login-part">
                    <div className="container">
                        <div className='login'>
                            <div className='login-card'>
                                <div className='card'>
                                    <form onSubmit={onSubmit}>
                                        <div className='login-header text-center'>
                                            <h4>Admin</h4>
                                        </div>
                                        {error.credentials && <span class="form-control alert alert-danger">{error.credentials}</span>}
                                        <div className='card-body'>

                                            <div className="mb-4">
                                                <label htmlFor="email" className="form-label">Username</label>
                                                <input type="text" className="form-control"
                                                    id="email" placeholder="Enter username"
                                                    value={state.email}
                                                    onChange={(event) => setState({ ...state, email: event.target.value })}
                                                    autoComplete="off" />
                                                {error.email && <span class="form-control alert alert-danger">{error.email}</span>}
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input type={showPassword ? 'text' : 'password'} className="form-control"
                                                    id="password" placeholder="Enter password"
                                                    value={state.password}
                                                    onChange={(event) => setState({ ...state, password: event.target.value })}
                                                    autoComplete="off" />
                                                <i
                                                    className={`fa fa-eye${showPassword ? '' : '-slash'}`}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    aria-hidden="true"></i>
                                                {error.password && <span class="form-control alert alert-danger">{error.password}</span>}
                                            </div>
                                            <div className="form-login-button pt-4">
                                                <button type="submit">Login</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
}

export default Login